<template>
  <div class="app-container">
    <!-- 城镇土地使用税 房产税申报表 -->
    <div class="top_title clearfix">
      <h4>城镇土地使用税 房产税申报表</h4>
      <div class="right_box">
          <el-button size="small" @click="printingNew()" type="success" plain>打印</el-button>
        </div>
      <div class="center_title clearfix">
      </div>
      <div class="bottom_time clearfix">
        <div class="top_input3 clearfix fr">
          <h6>纳税人识别号：</h6>
          <span>{{taxNo}}</span>
        </div>
      </div>
      <div class="bottom_time clearfix">
        <div class="top_input2 clearfix fl">
          <h6>纳税人名称（公章）：</h6>
          <span>{{comName}}</span>
        </div>
        <div class="top_input3 clearfix fr">
          <h6>金额单位：元（列至角分）</h6>
          <span></span>
        </div>
      </div>
    </div>
    <!-- 顶部标题 end -->

    <table class="content" cellspacing="0">
       <i @click="addItem('tds')" class="iconfont icon-jiahao addi"></i>      
      <tr class="top_bg">
        <td colspan="13" class="center weight">一、城镇土地使用税减免信息</td>
      </tr>
      <tr class="">
        <td class="center weight">序号</td>
        <td class="center weight">土地编号</td>
        <td class="center weight">宗地号</td>
        <td class="center weight">土地等级</td>
        <td class="center weight">税额标准 </td>
        <td class="center weight">土地总面积</td>
        <td class="center weight">本期应纳税额</td>
        <td class="center weight">本期减免税额</td>
        <td class="center weight">本期增值税小规模纳税人减征额</td>
        <td class="center weight">本期已缴税额</td>
        <td class="center weight">本期应补(退) 税额</td>
      </tr>
      <tr v-for="(item,index) in tds" :key="index" class="iconFa">
        <!-- <i class="iconfont icon-jiahao"></i> -->
        <td class="center weight"><input type="text" v-model.number="item.num"></td>
        <td class="center weight"><input type="text" v-model="item.tdbh"></td>
        <td class="center weight"><input type="text" v-model="item.zdh"></td>
        <td class="center weight"><input type="text" v-model="item.tddj"></td>
        <td class="center weight"><input type="text" v-model.number="item.sebz"></td>
        <td class="center weight"><input type="text" v-model.number="item.tdzmj"></td>
        <!-- <td class="center weight"><input disabled type="text" v-model="item.ssqq"></td>
        <td class="center weight"><input disabled type="text" v-model="item.ssqz"></td> -->
        <td class="center weight"><input type="text" v-model.number="item.bqynse"></td>
        <td class="center weight"><input type="text" v-model.number="item.bqjmse"></td>
        <td class="center weight"><input type="text" v-model.number="item.bqzzsxgmnsrjmse"></td>
        <td class="center weight"><input type="text" v-model.number="item.bqyjse"></td>
        <td class="center weight"><input type="text" v-model.number="item.bqybtse"></td>
        <i @click="delItem('tds',index)" class="iconfont icon-shanchu"></i>
      </tr>
      <tr class="">
        <td class="center weight">合计</td>
        <td class="center weight">--</td>
        <td class="center weight">--</td>
        <td class="center weight">--</td>
        <td class="center weight">--</td>
        <td class="center weight">{{tdsSum.a1}}</td>
        <td class="center weight">{{tdsSum.a2}}</td>
        <td class="center weight">{{tdsSum.a3}}</td>
        <td class="center weight">{{tdsSum.a4}}</td>
        <td class="center weight">{{tdsSum.a5}}</td>
        <td class="center weight">{{tdsSum.a6}}</td>
      </tr>
    </table>

    <table class="content" cellspacing="0">
      <i @click="addItem('fcs1')" class="iconfont icon-jiahao addi"></i>      
      <tr class="top_bg">
        <td colspan="13" class="center weight">二、房产税减免信息</td>
      </tr>
      <tr class="">
        <td colspan="13" class="center weight">（一）从价计征房产税减免信息</td>
      </tr>
      <tr class="">
        <td class="center weight">序号</td>
        <td class="center weight">房产编号</td>
        <td class="center weight">房产原值</td>
        <td class="center weight">其中：出租房产原值</td>
        <td class="center weight">计税比例</td>
        <td class="center weight">税率</td>
        <!-- <td class="center weight">所属期起</td>
        <td class="center weight">所属期止</td> -->
        <td class="center weight">本期应纳税额</td>
        <td class="center weight">本期减免税额</td>
        <td class="center weight">本期增值税小规模纳税人减征额</td>
        <td class="center weight">本期已缴纳额</td>
        <td class="center weight">本期应补（退）税额</td>
      </tr>
      <tr v-for="(item,index) in fcs1" :key="index" class="iconFa">
        <td class="center weight"><input type="text" v-model.number="item.num"></td>
        <td class="center weight"><input type="text" v-model="item.fcbh"></td>
        <td class="center weight"><input type="text" v-model.number="item.fcyz"></td>
        <td class="center weight"><input type="text" v-model.number="item.qzczfcyz"></td>
        <td class="center weight"><input type="text" v-model="item.jsbl"></td>
        <td class="center weight"><input type="text" v-model="item.sl"></td>
        <!-- <td class="center weight"><input disabled type="text" v-model="item.ssqq"></td>
        <td class="center weight"><input disabled type="text" v-model="item.ssqz"></td> -->
        <td class="center weight"><input type="text" v-model.number="item.bqynse"></td>
        <td class="center weight"><input type="text" v-model.number="item.bqjmse"></td>
        <td class="center weight"><input type="text" v-model.number="item.bqzzsxgmnsrjmse"></td>
        <td class="center weight"><input type="text" v-model.number="item.bqyjse"></td>
        <td class="center weight"><input type="text" v-model.number="item.bqybtse"></td>
        <i @click="delItem('fcs1',index)" class="iconfont icon-shanchu"></i>
      </tr>
      <tr class="">
        <td class="center weight">合计</td>
        <td class="center weight">--</td>
        <td class="center weight">--</td>
        <td class="center weight">--</td>
        <td class="center weight">--</td>
        <td class="center weight">--</td>
        <!-- <td class="center weight">--</td>
        <td class="center weight">--</td> -->
        <td class="center weight">{{fcs1Sum.a2}}</td>
        <td class="center weight">{{fcs1Sum.a3}}</td>
        <td class="center weight">{{fcs1Sum.a4}}</td>
        <td class="center weight">{{fcs1Sum.a5}}</td>
        <td class="center weight">{{fcs1Sum.a6}}</td>
      </tr>
    </table>

    <table class="content" cellspacing="0">
      <i @click="addItem('fcs2')" class="iconfont icon-jiahao addi"></i>      
      <tr class="">
        <td colspan="10" class="center weight">（二）从租计征房产税减免信息</td>
      </tr>
      <tr class="">
        <td class="center weight">序号</td>
        <td class="center weight">本期申报租金收入</td>
        <td class="center weight">税率</td>
        <td class="center weight">所属期起</td>
        <td class="center weight">所属期止</td>
        <td class="center weight">本期应纳税额</td>
        <td class="center weight">本期减免税额</td>
        <td class="center weight">本期增值税小规模纳税人减征额</td>
        <td class="center weight">本期已缴纳额</td>
        <td class="center weight">本期应补（退）税额</td>
      </tr>
      <tr v-for="(item,index) in fcs2" :key="index" class="iconFa">
        <td class="center weight"><input type="text" v-model.number="item.num"></td>
        <td class="center weight"><input type="text" v-model.number="item.bqsbzjsr"></td>
        <td class="center weight"><input type="text" v-model="item.sl"></td>
        <td class="center weight"><input disabled type="text" v-model="item.ssqq"></td>
        <td class="center weight"><input disabled type="text" v-model="item.ssqz"></td>
        <td class="center weight"><input type="text" v-model.number="item.bqynse"></td>
        <td class="center weight"><input type="text" v-model.number="item.bqjmse"></td>
        <td class="center weight"><input type="text" v-model.number="item.bqzzsxgmnsrjmse"></td>
        <td class="center weight"><input type="text" v-model.number="item.bqyjse"></td>
        <td class="center weight"><input type="text" v-model.number="item.bqybtse"></td>
        <i @click="delItem('fcs2',index)" class="iconfont icon-shanchu"></i>
      </tr>
      <tr class="">
        <td class="center weight">合计</td>
        <td class="center weight">--</td>
        <td class="center weight">--</td>
        <td class="center weight">--</td>
        <td class="center weight">--</td>
        <td class="center weight">{{fcs2Sum.a2}}</td>
        <td class="center weight">{{fcs2Sum.a3}}</td>
        <td class="center weight">{{fcs2Sum.a4}}</td>
        <td class="center weight">{{fcs2Sum.a5}}</td>
        <td class="center weight">{{fcs2Sum.a6}}</td>
      </tr>
    </table>

    <div class="save_box">
      <el-button type="primary" @click="saveValues()" size="small" plain>保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'fcs',
  data() {
    return {
      item:{},
      durationOfTaxTime: '',
     activeName: 'first',
     value1: '',
     edit: false,
     info: [],
     sum: {},
     comName:this.$store.getters['user/comInfo'].comName,
     taxNo:this.$store.getters['user/comInfo'].taxNo,
     tds: [],
     fcs1: [],
     fcs2: [],
    }
  },
  computed: {
    tdsSum(){
      let obj = {}
      let sum1 = 0
      let sum2 = 0
      let sum3 = 0
      let sum4 = 0
      let sum5 = 0
      let sum6 = 0
      this.tds.map(v=>{
        sum1 += v.tdzmj * 1
        sum2 += v.bqynse * 1
        sum3 += v.bqjmse * 1
        sum4 += v.bqzzsxgmnsrjmse * 1
        sum5 += v.bqyjse * 1
        sum6 += v.bqybtse * 1
      })
      obj.a1 = sum1.toFixed(2)
      obj.a2 = sum2.toFixed(2)
      obj.a3 = sum3.toFixed(2)
      obj.a4 = sum4.toFixed(2)
      obj.a5 = sum5.toFixed(2)
      obj.a6 = sum6.toFixed(2)
      return obj
    },
    fcs1Sum(){
      let obj = {}
      let sum1 = 0;
      let sum2 = 0;
      let sum3 = 0;
      let sum4 = 0;
      let sum5 = 0;
      let sum6 = 0;
      this.fcs1.map(v=>{
        sum2 += v.bqynse * 1
        sum3 += v.bqjmse * 1
        sum4 += v.bqzzsxgmnsrjmse * 1
        sum5 += v.bqyjse * 1
        sum6 += v.bqybtse * 1
      })
      obj.a1 = sum1.toFixed(2)
      obj.a2 = sum2.toFixed(2)
      obj.a3 = sum3.toFixed(2)
      obj.a4 = sum4.toFixed(2)
      obj.a5 = sum5.toFixed(2)
      obj.a6 = sum6.toFixed(2)
      return obj
    },
    fcs2Sum(){
      let obj = {}
      let sum1 = 0
      let sum2 = 0
      let sum3 = 0
      let sum4 = 0
      let sum5 = 0
      let sum6 = 0
      this.fcs2.map(v=>{
        sum2 += v.bqynse * 1
        sum3 += v.bqjmse * 1
        sum4 += v.bqzzsxgmnsrjmse * 1
        sum5 += v.bqyjse * 1
        sum6 += v.bqybtse * 1
      })
      obj.a1 = sum1.toFixed(2)
      obj.a2 = sum2.toFixed(2)
      obj.a3 = sum3.toFixed(2)
      obj.a4 = sum4.toFixed(2)
      obj.a5 = sum5.toFixed(2)
      obj.a6 = sum6.toFixed(2)
      return obj
    },
  },
  methods: {
    // 公式
    changeList(){

    },
    addItem(type){
      if(type == 'tds'){
        this.tds.push({
          bqynse: 0,
          tdzmj: 0,
          bqjmse: 0,
          bqzzsxgmnsrjmse: 0,
          bqyjse: 0,
          bqybtse: 0,
          ssqq:'',
          ssqz:'',
          // ssqq: durationOfTax(period,2).split("至")[0],
          // ssqz: durationOfTax(period,2).split("至")[1]
        })
      }else if(type == 'fcs1'){
        this.fcs1.push({
          bqynse: 0,
          bqjmse: 0,
          bqzzsxgmnsrjmse: 0,
          bqyjse: 0,
          bqybtse: 0,
          ssqq:'',
          ssqz:'',
          // ssqq: durationOfTax(period,2).split("至")[0],
          // ssqz: durationOfTax(period,2).split("至")[1]
        })
      }else if(type == 'fcs2'){
        this.fcs2.push({
          bqynse: 0,
          bqjmse: 0,
          bqzzsxgmnsrjmse: 0,
          bqyjse: 0,
          bqybtse: 0,
          ssqq:'',
          ssqz:'',
          // ssqq: durationOfTax(period,2).split("至")[0],
          // ssqz: durationOfTax(period,2).split("至")[1]
        })
      }
    },
    delItem(type,index){
      if(type == 'tds'){
        this.tds.splice(index,1)
      }else if(type == 'fcs1'){
        this.fcs1.splice(index,1)
      }else if(type == 'fcs2'){
        this.fcs2.splice(index,1)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.save_box{
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.top_title{
  width: 100%;
  margin-bottom: 20px;
  h4{
    font-size: 20px;
    color: #333;
    font-weight: 700;
    margin: 10px auto;
    text-align: center;
  }
  .center_title{
    // float: left;
    width:30%;
    margin: 0 auto;
    text-align: center;
    h6{
      font-size: 14px;
      color: #333;
      font-weight: normal;
    }
    input{
    font-size: 14px;
    color: #333;
    line-height: 32px;
    }
  }
  .right_num{
    float: right;
    width:20%;
    text-align: right;
    font-size: 14px;
    color: #333;
  }
  .bottom_time{
    width: 100%;
    margin-top: 10px;
  }
  .top_input2{
    width: 50%;
    h6{
      display: inline-block;
      // width: 70px;
      line-height: 28px;
      font-size: 14px;
      font-weight: normal;
    }
  }
  .top_input3{
    width: 50%;
    text-align: right;
    h6{
      display: inline-block;
      // width: 100px;
      line-height: 32px;
      font-size: 14px;
      font-weight: normal;
    }

  }
}
.content{
  position: relative;
  margin-left: 14px;
  width: 99%;
  // margin:  auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  .addi{
    cursor: pointer;
    position: absolute;
    top: 30px;
    left: -26px;
    font-size: 22px;
    color: $color-main;
  }
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 6px;
    font-size: 14px;
  }
}
.app-container{
  height: 100%;
  position: relative;
  padding: 20px;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 14px !important;
  font-weight: 600;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.iconFa{
  position: relative;
  i{
    cursor: pointer;
    font-size: 22px;
  }
  .iconfont icon-shanchu{
    color: #F56C6C;
    position: absolute;
  }
}
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
  
}
</style>